import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, GeoJSON, TileLayer, ZoomControl, Marker, Tooltip, LayersControl, LayerGroup} from 'react-leaflet';
import styles from './Home.module.scss';
import className from 'classnames/bind';
import Tram from '~/Api/Tram';
import IconTram from '~/Assets/Images/tram.png';
import MapPopup from '~/Components/Controls/MapPopup';
import RiverPopup from '~/Components/Controls/RiverPopup';
import PanelLayer from '~/Components/Controls/PanelLayer';
import NoteLayer from '~/Components/Controls/NoteLayer';
import DensityPopup from '~/Components/Controls/DensityPopup';
import BanDoHuyen from '~/Assets/GeoJSON/ban_do_huyen_tinh_VL.json';
import L from 'leaflet';
import LMax_CoChien_T1_1 from '~/Assets/GeoJSON/LMax_CoChien/T1/LMax_CoChien_T1_1.json';
import LMax_CoChien_T1_2 from '~/Assets/GeoJSON/LMax_CoChien/T1/LMax_CoChien_T1_2.json';
import LMax_CoChien_T1_3 from '~/Assets/GeoJSON/LMax_CoChien/T1/LMax_CoChien_T1_3.json';
import LMax_CoChien_T1_4 from '~/Assets/GeoJSON/LMax_CoChien/T1/LMax_CoChien_T1_4.json';
import LMax_CoChien_T1_5 from '~/Assets/GeoJSON/LMax_CoChien/T1/LMax_CoChien_T1_5.json';
import LMax_CoChien_T2_1 from '~/Assets/GeoJSON/LMax_CoChien/T2/LMax_CoChien_T2_1.json';
import LMax_CoChien_T2_2 from '~/Assets/GeoJSON/LMax_CoChien/T2/LMax_CoChien_T2_2.json';
import LMax_CoChien_T2_3 from '~/Assets/GeoJSON/LMax_CoChien/T2/LMax_CoChien_T2_3.json';
import LMax_CoChien_T2_4 from '~/Assets/GeoJSON/LMax_CoChien/T2/LMax_CoChien_T2_4.json';
import LMax_CoChien_T2_5 from '~/Assets/GeoJSON/LMax_CoChien/T2/LMax_CoChien_T2_5.json';
import LMax_CoChien_T3_1 from '~/Assets/GeoJSON/LMax_CoChien/T3/LMax_CoChien_T3_1.json';
import LMax_CoChien_T3_2 from '~/Assets/GeoJSON/LMax_CoChien/T3/LMax_CoChien_T3_2.json';
import LMax_CoChien_T3_3 from '~/Assets/GeoJSON/LMax_CoChien/T3/LMax_CoChien_T3_3.json';
import LMax_CoChien_T3_4 from '~/Assets/GeoJSON/LMax_CoChien/T3/LMax_CoChien_T3_4.json';
import LMax_CoChien_T3_5 from '~/Assets/GeoJSON/LMax_CoChien/T3/LMax_CoChien_T3_5.json';
import LMax_CoChien_T4_1 from '~/Assets/GeoJSON/LMax_CoChien/T4/LMax_CoChien_T4_1.json';
import LMax_CoChien_T4_2 from '~/Assets/GeoJSON/LMax_CoChien/T4/LMax_CoChien_T4_2.json';
import LMax_CoChien_T4_3 from '~/Assets/GeoJSON/LMax_CoChien/T4/LMax_CoChien_T4_3.json';
import LMax_CoChien_T4_4 from '~/Assets/GeoJSON/LMax_CoChien/T4/LMax_CoChien_T4_4.json';
import LMax_CoChien_T4_5 from '~/Assets/GeoJSON/LMax_CoChien/T4/LMax_CoChien_T4_5.json';
import LMax_CoChien_T5_1 from '~/Assets/GeoJSON/LMax_CoChien/T5/LMax_CoChien_T5_1.json';
import LMax_CoChien_T5_2 from '~/Assets/GeoJSON/LMax_CoChien/T5/LMax_CoChien_T5_2.json';
import LMax_CoChien_T5_3 from '~/Assets/GeoJSON/LMax_CoChien/T5/LMax_CoChien_T5_3.json';
import LMax_CoChien_T5_4 from '~/Assets/GeoJSON/LMax_CoChien/T5/LMax_CoChien_T5_4.json';
import LMax_CoChien_T5_5 from '~/Assets/GeoJSON/LMax_CoChien/T5/LMax_CoChien_T5_5.json';
import LMax_SongHau_T1_1 from '~/Assets/GeoJSON/LMax_SongHau/T1/LMax_SongHau_T1_1.json';
import LMax_SongHau_T1_2 from '~/Assets/GeoJSON/LMax_SongHau/T1/LMax_SongHau_T1_2.json';
import LMax_SongHau_T1_3 from '~/Assets/GeoJSON/LMax_SongHau/T1/LMax_SongHau_T1_3.json';
import LMax_SongHau_T1_4 from '~/Assets/GeoJSON/LMax_SongHau/T1/LMax_SongHau_T1_4.json';
import LMax_SongHau_T1_5 from '~/Assets/GeoJSON/LMax_SongHau/T1/LMax_SongHau_T1_5.json';
import LMax_SongHau_T2_1 from '~/Assets/GeoJSON/LMax_SongHau/T2/LMax_SongHau_T2_1.json';
import LMax_SongHau_T2_2 from '~/Assets/GeoJSON/LMax_SongHau/T2/LMax_SongHau_T2_2.json';
import LMax_SongHau_T2_3 from '~/Assets/GeoJSON/LMax_SongHau/T2/LMax_SongHau_T2_3.json';
import LMax_SongHau_T2_4 from '~/Assets/GeoJSON/LMax_SongHau/T2/LMax_SongHau_T2_4.json';
import LMax_SongHau_T2_5 from '~/Assets/GeoJSON/LMax_SongHau/T2/LMax_SongHau_T2_5.json';
import LMax_SongHau_T3_1 from '~/Assets/GeoJSON/LMax_SongHau/T3/LMax_SongHau_T3_1.json';
import LMax_SongHau_T3_2 from '~/Assets/GeoJSON/LMax_SongHau/T3/LMax_SongHau_T3_2.json';
import LMax_SongHau_T3_3 from '~/Assets/GeoJSON/LMax_SongHau/T3/LMax_SongHau_T3_3.json';
import LMax_SongHau_T3_4 from '~/Assets/GeoJSON/LMax_SongHau/T3/LMax_SongHau_T3_4.json';
import LMax_SongHau_T3_5 from '~/Assets/GeoJSON/LMax_SongHau/T3/LMax_SongHau_T3_5.json';
import LMax_SongHau_T4_1 from '~/Assets/GeoJSON/LMax_SongHau/T4/LMax_SongHau_T4_1.json';
import LMax_SongHau_T4_2 from '~/Assets/GeoJSON/LMax_SongHau/T4/LMax_SongHau_T4_2.json';
import LMax_SongHau_T4_3 from '~/Assets/GeoJSON/LMax_SongHau/T4/LMax_SongHau_T4_3.json';
import LMax_SongHau_T4_4 from '~/Assets/GeoJSON/LMax_SongHau/T4/LMax_SongHau_T4_4.json';
import LMax_SongHau_T4_5 from '~/Assets/GeoJSON/LMax_SongHau/T4/LMax_SongHau_T4_5.json';
import LMax_SongHau_T5_1 from '~/Assets/GeoJSON/LMax_SongHau/T5/LMax_SongHau_T5_1.json';
import LMax_SongHau_T5_2 from '~/Assets/GeoJSON/LMax_SongHau/T5/LMax_SongHau_T5_2.json';
import LMax_SongHau_T5_3 from '~/Assets/GeoJSON/LMax_SongHau/T5/LMax_SongHau_T5_3.json';
import LMax_SongHau_T5_4 from '~/Assets/GeoJSON/LMax_SongHau/T5/LMax_SongHau_T5_4.json';
import LMax_SongHau_T5_5 from '~/Assets/GeoJSON/LMax_SongHau/T5/LMax_SongHau_T5_5.json';

const css = className.bind(styles);

const Home = () => {
    const [lat, setLat] = useState(10.095117);
    const [lon, setLon] = useState(106.067785);
    const [zoom, setZoom] = useState(10);
    const [time, setTime] = useState("1");
    const [river, setRiver] = useState(1);
    const [listTram, setListTram] = useState([]);
    
    const [isShowChart, setShowChart] = useState(false);
    const [isShowRiverChart, setShowRiverChart] = useState(false);
    const [tramId, setTramId] = useState(0);
    const [tramName, setTramName] = useState('');

    const [showNote, setShowNote] = useState(false);
    const [showDensity, setShowDensity] = useState(false);

    const density = useRef(null);
    const note = useRef(null);

    useEffect(() => {
        const tram = new Tram;
        tram.GetListTram().then(res => {
            setListTram(res.data);
        });
    },[]);

    useEffect(() => {
        function handleClickOutside(event) {
          if (note.current && !note.current.contains(event.target)) {
            setShowNote(false);
          }
          if (density.current && !density.current.contains(event.target)) {
            setShowDensity(false);
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    const customIcon = new L.Icon({
        iconUrl: IconTram,
        iconSize: [20, 20]
    });

    const customIconRemove = new L.Icon({
        iconUrl: IconTram,
        iconSize: [20, 20],
        fillColor: 'transparent',
        color: 'white'
    });

    const handleClickMarket = () => {
        setShowChart(true);
    };

    const handleClickRiver1 = () => {
        setShowRiverChart(true);
        setRiver(1);
    };

    const handleClickRiver2 = () => {
        setShowRiverChart(true);
        setRiver(2);
    };

    const eachFeatureVL = (f,l) => {
        setShowRiverChart(true);
    };

    const geoJsonStyle1 = {
        fillColor: "#ff0000",
        color: "#ff0000",
        fillOpacity: 1.0,
    };

    const geoJsonStyle2 = {
        fillColor: "#ffa500",
        color: "#ffa500",
        fillOpacity: 1.0,
    };

    const geoJsonStyle3 = {
        fillColor: "#ffff00",
        color: "#ffff00",
        fillOpacity: 1.0,
    };

    const geoJsonStyle4 = {
        fillColor: "#00ff00",
        color: "#00ff00",
        fillOpacity: 1.0,
    };

    const geoJsonStyle5 = {
        fillColor: "#00ffff",
        color: "#00ffff",
        fillOpacity: 1.0,
    };

    const handleGetValue = (e) => {
        setTime(e);
    };

    const handleMarkerTram = (x,name) => {
        setShowChart(true);
        setTramId(x);
        setTramName(name);
    };

    return (
        <div style={{width: 'auto', height:'91.5vh'}}>
            <MapContainer
                center={[lat, lon]}
                zoom={zoom}
                minZoom={10}
                scrollWheelZoom={true}
                style={{ height: '100%', width: '100%' }}
                zoomControl={false}
                attributionControl={false}
            >
                <TileLayer 
                    url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                    key="AIzaSyDc7PnOq3Hxzq6dxeUVaY8WGLHIePl0swY"
                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                />
                <LayersControl position="topleft">
                    <LayersControl.BaseLayer checked name="Mặc định">
                        <TileLayer 
                            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                            key="AIzaSyDc7PnOq3Hxzq6dxeUVaY8WGLHIePl0swY"
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Google Map (2D)">
                        <LayerGroup>
                            <TileLayer 
                                url="https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}&hl=vi&gl=vn" 
                                key="AIzaSyDc7PnOq3Hxzq6dxeUVaY8WGLHIePl0swY"
                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            />
                        </LayerGroup>
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Google Map (Vệ tinh)">
                        <LayerGroup>
                            <TileLayer 
                                url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}&hl=vi&gl=vn"  
                                key="AIzaSyDc7PnOq3Hxzq6dxeUVaY8WGLHIePl0swY"
                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            />
                        </LayerGroup>
                    </LayersControl.BaseLayer>
                </LayersControl>
                <PanelLayer
                    value={handleGetValue}
                />
                {listTram && listTram.map((x) => {
                    return(<>
                        <Marker position={[x.latitude,x.longitude]} icon={customIconRemove}>
                            <Tooltip permanent className={css('label-tooltip-1')}>
                                <span>{x.name}</span>
                            </Tooltip>
                        </Marker>
                        <Marker position={[x.latitude,x.longitude]} icon={customIcon} eventHandlers={{ click: () => handleMarkerTram(x.id, x.name) }}/>
                    </>)
                })}
                {window.innerWidth>768 && (
                    <>
                        <NoteLayer/>
                        <DensityPopup/> 
                    </>
                )}
                {window.innerWidth<=768 && (
                    <>
                        {!showDensity&&(
                            <div style={{
                                position: 'absolute',
                                bottom: '0.5rem',
                                left: '1rem',
                                backgroundColor: 'white',
                                padding: '1rem',
                                borderRadius: '0.5rem',
                                boxShadow: '0 0.2rem 0.5rem rgba(0,0,0,0.1)',
                                cursor: 'pointer',
                                zIndex: '401'
                            }}
                                onClick={() => setShowDensity(true)}
                            >Độ mặn</div>
                        )}
                        {!showNote&&(
                            <div style={{
                                position: 'absolute',
                                bottom: '0.5rem',
                                right: '1rem',
                                backgroundColor: 'white',
                                padding: '1rem',
                                borderRadius: '0.5rem',
                                boxShadow: '0 0.2rem 0.5rem rgba(0,0,0,0.1)',
                                cursor: 'pointer',
                                zIndex: '401'
                            }}
                                onClick={() => setShowNote(true)}
                            >Chú thích</div> 
                        )}
                        {showDensity&&(
                            <div ref={density}>
                                <DensityPopup/>
                            </div>
                        )}
                        {showNote&&(
                            <div ref={note}>
                                <NoteLayer/>
                            </div>
                        )}
                    </>
                )}
                  
                <GeoJSON data={BanDoHuyen}/>
                {/* Tháng 1 */}
                {time === "1"&& <>
                    <GeoJSON key={'1'} data={LMax_CoChien_T1_1} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle1}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 4</sub> = </p>
                                <p>40,8</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'2'} data={LMax_CoChien_T1_2} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle2}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 2</sub> = </p>
                                <p>50,6</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'3'} data={LMax_CoChien_T1_3} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle3}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 1</sub> = </p>
                                <p>58,4</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'4'} data={LMax_CoChien_T1_4} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle4}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 0,5</sub> = </p>
                                <p>63,4</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'5'} data={LMax_CoChien_T1_5} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle5}/>
                    <GeoJSON key={'6'} data={LMax_SongHau_T1_1} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle1}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 4</sub> = </p>
                                <p>48,1</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'7'} data={LMax_SongHau_T1_2} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle2}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 2</sub> = </p>
                                <p>55,3</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'8'} data={LMax_SongHau_T1_3} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle3}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 1</sub> = </p>
                                <p>58,5</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'9'} data={LMax_SongHau_T1_4} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle4}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 0,5</sub> = </p>
                                <p>62,6</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'10'} data={LMax_SongHau_T1_5} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle5}/>
                </>}
                {/* Tháng 2 */}
                {time === "2" && <>
                    <GeoJSON key={'11'} data={LMax_CoChien_T2_1} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle1}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 4</sub> = </p>
                                <p>48,7</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'12'} data={LMax_CoChien_T2_2} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle2}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 2</sub> = </p>
                                <p>59,7</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'13'} data={LMax_CoChien_T2_3} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle3}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 1</sub> = </p>
                                <p>64,3</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'14'} data={LMax_CoChien_T2_4} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle4}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 0,5</sub> = </p>
                                <p>68,5</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'15'} data={LMax_CoChien_T2_5} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle5}/>
                    <GeoJSON key={'16'} data={LMax_SongHau_T2_1} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle1}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 4</sub> = </p>
                                <p>46</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'17'} data={LMax_SongHau_T2_2} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle2}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 2</sub> = </p>
                                <p>56,4</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'18'} data={LMax_SongHau_T2_3} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle3}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 1</sub> = </p>
                                <p>64</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'19'} data={LMax_SongHau_T2_4} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle4}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 0,5</sub> = </p>
                                <p>68</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'20'} data={LMax_SongHau_T2_5} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle5}/>
                </>}
                {/* Tháng 3 */}
                {time === "3" && <>
                    <GeoJSON key={'21'} data={LMax_CoChien_T3_1} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle1}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 4</sub> = </p>
                                <p>47</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'22'} data={LMax_CoChien_T3_2} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle2}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 2</sub> = </p>
                                <p>55,8</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'23'} data={LMax_CoChien_T3_3} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle3}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 1</sub> = </p>
                                <p>65,9</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'24'} data={LMax_CoChien_T3_4} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle4}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 0,5</sub> = </p>
                                <p>73,9</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'25'} data={LMax_CoChien_T3_5} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle5}/>
                    <GeoJSON key={'26'} data={LMax_SongHau_T3_1} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle1}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 4</sub> = </p>
                                <p>50,6</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'27'} data={LMax_SongHau_T3_2} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle2}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 2</sub> = </p>
                                <p>55</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'28'} data={LMax_SongHau_T3_3} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle3}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 1</sub> = </p>
                                <p>60,7</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'29'} data={LMax_SongHau_T3_4} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle4}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 0,5</sub> = </p>
                                <p>66,7</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'30'} data={LMax_SongHau_T3_5} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle5}/>
                </>}
                {/* Tháng 4 */}
                {time === "4" && <>
                    <GeoJSON key={'31'} data={LMax_CoChien_T4_1} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle1}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 4</sub> = </p>
                                <p>40,3</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'32'} data={LMax_CoChien_T4_2} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle2}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 2</sub> = </p>
                                <p>49,5</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'33'} data={LMax_CoChien_T4_3} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle3}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 1</sub> = </p>
                                <p>55,8</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'34'} data={LMax_CoChien_T4_4} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle4}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 0,5</sub> = </p>
                                <p>68,5</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'35'} data={LMax_CoChien_T4_5} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle5}/>
                    <GeoJSON key={'36'} data={LMax_SongHau_T4_1} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle1}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 4</sub> = </p>
                                <p>39,4</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'37'} data={LMax_SongHau_T4_2} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle2}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 2</sub> = </p>
                                <p>49,9</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'38'} data={LMax_SongHau_T4_3} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle3}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 1</sub> = </p>
                                <p>58,4</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'39'} data={LMax_SongHau_T4_4} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle4}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 0,5</sub> = </p>
                                <p>63,8</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'40'} data={LMax_SongHau_T4_5} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle5}/>
                </>}  
                {/* Tháng 5 */}
                {time === "5" && <>
                    <GeoJSON key={'41'} data={LMax_CoChien_T5_1} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle1}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 4</sub> = </p>
                                <p>41,1</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'42'} data={LMax_CoChien_T5_2} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle2}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 2</sub> = </p>
                                <p>53,4</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'43'} data={LMax_CoChien_T5_3} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle3}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 1</sub> = </p>
                                <p>61,4</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'44'} data={LMax_CoChien_T5_4} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle4}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 0,5</sub> = </p>
                                <p>66,6</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'45'} data={LMax_CoChien_T5_5} eventHandlers={{click: handleClickRiver1}} style={geoJsonStyle5}/>
                    <GeoJSON key={'46'} data={LMax_SongHau_T5_1} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle1}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 4</sub> = </p>
                                <p>34,6</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'47'} data={LMax_SongHau_T5_2} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle2}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 2</sub> = </p>
                                <p>47,9</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'48'} data={LMax_SongHau_T5_3} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle3}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 1</sub> = </p>
                                <p>56,2</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'49'} data={LMax_SongHau_T5_4} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle4}>
                        <Tooltip direction="top" permanent>
                            <div className={css('tooltip-row')}>
                                <p style={{paddingRight: '0.5rem'}}>L<sub>Max 0,5</sub> = </p>
                                <p>62,7</p>
                            </div>
                        </Tooltip>
                    </GeoJSON>
                    <GeoJSON key={'50'} data={LMax_SongHau_T5_5} eventHandlers={{click: handleClickRiver2}} style={geoJsonStyle5}/>
                </>}
                <ZoomControl position="topleft" />
            </MapContainer>
            <MapPopup
                show={isShowChart}
                onHide={() => setShowChart(false)}
                tramId={tramId}
                tram={tramName}
            />
            <RiverPopup
                show={isShowRiverChart}
                onHide={() => setShowRiverChart(false)}
                river={river}
            />
        </div>);
};

export default Home;