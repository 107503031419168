import { appConfig } from '~/Config/AppConfig';
import axios from 'axios';

const path = appConfig.API+'/api/v1/tram/';

class Tram {
    GetListTram() {
        return axios.get(path+`get-list-tram`)
        .then(response => response.data)
        .catch(error => error.response.data);
    };
}

export default Tram;