import { DefaultLayout } from '../Components/Layouts';
import Home from '~/Pages/Home';
import About from '~/Pages/About';

const PublicRoutes = [
    { path: '/', component: Home, layout: DefaultLayout },
    { path: '/About', component: About, layout: DefaultLayout },
];

const PrivateRoutes = [];

export { PublicRoutes, PrivateRoutes };
