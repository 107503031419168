import React, { useState } from 'react';
import { useMap } from 'react-leaflet';
import styles from './PanelLayer.module.scss';
import className from 'classnames/bind';

const css = className.bind(styles);

const PanelLayer = ({value}) => {
    const map = useMap();
    const [selectedOption, setSelectedOption] = useState("1");

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        value(e.target.value);
    };

    const handleClick = () => {
        // Xử lý khi control được click
        alert('Custom control clicked!');
    };
    return (<div
        style={{
            position: 'absolute',
            top: '0.5rem',
            right: '1rem',
            backgroundColor: 'white',
            padding: '1rem',
            borderRadius: '0.5rem',
            boxShadow: '0 0.2rem 0.5rem rgba(0,0,0,0.1)',
            cursor: 'pointer',
            zIndex: '401',
            border: '2px solid rgba(0, 0, 0, 0.2)'
        }}
    >
        <div className={css('label')}>
            <input
                type="radio"
                value="1"
                checked={selectedOption === "1"}
                onChange={handleOptionChange}
            />
            <p>Tháng 1/2024</p>
        </div>
        <div className={css('label')}>
            <input
                type="radio"
                value="2"
                checked={selectedOption === "2"}
                onChange={handleOptionChange}
            />
            <p>Tháng 2/2024</p>
        </div>
        <div className={css('label')}>
            <input
                type="radio"
                value="3"
                checked={selectedOption === "3"}
                onChange={handleOptionChange}
            />
            <p>Tháng 3/2024</p>
        </div>
        <div className={css('label')}>
            <input
                type="radio"
                value="4"
                checked={selectedOption === "4"}
                onChange={handleOptionChange}
            />
            <p>Tháng 4/2024</p>
        </div>
        <div className={css('label')}>
            <input
                type="radio"
                value="5"
                checked={selectedOption === "5"}
                onChange={handleOptionChange}
            />
            <p>Tháng 5/2024</p>
        </div>
    </div>);
};

export default PanelLayer;