import React, { useState } from 'react';
import className from 'classnames/bind';
import Modal from 'react-bootstrap/Modal';
import styles from './LoginPopup.module.scss';
import Button from 'react-bootstrap/Button';

const css = className.bind(styles);

const LoginPopup = ({show=true, onHide}) => {
    return (<Modal
        size="sm"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className={css('header')}>
                ĐĂNG NHẬP
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className={css('label')}>Tài khoản</p>
            <input className={css('input')} type='text'/>
            <p className={css('label')}>Mật khẩu</p>
            <input className={css('input')} type='password'/>
        </Modal.Body>
        <Modal.Footer>
            <Button className={css('btn')} variant="success">Đăng nhập</Button>
        </Modal.Footer>
    </Modal>);
};

export default LoginPopup;