import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './MapPopup.module.scss';
import className from 'classnames/bind';
import { format } from 'date-fns';
import Data from '~/Api/Data';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const css = className.bind(styles);

const MapPopup = ({show=true,onHide, tram='', tramId=''}) => {
    const [fromDate, setFromDate] = useState('2024-01-01');
    const [toDate, setToDate] = useState('2024-02-01');
    const [listInfo, setListInfo] = useState([]);
    const [listDate, setListDate] = useState([]);
    const [listSal, setListSal] = useState([]);
    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    useEffect(() => {
        const data = new Data();
        const ldate = [];
        const lsal = [];
        data.GetDateByDateTime(tramId, fromDate, toDate).then((x) => {
            if(x.data) {
                setListInfo(x.data);
                x.data.forEach(y => {
                    ldate.push(format(new Date(y.dateTime), 'HH:00:00 dd/MM/yyyy'));
                    lsal.push(y.sal);
                });
            }
        });
        setListDate(ldate);
        setListSal(lsal);
    },[tramId, fromDate, toDate]);

    // Hàm định dạng ngày giờ
const formatDate = (date) => {
    return format(new Date(date), 'dd/MM/yyyy HH:mm');
  };
  
  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(0)" fontSize="1.2rem">
          {formatDate(payload.value)}
        </text>
      </g>
    );
  };

    return(<Modal size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className={css('header')}>
                Độ mặn tại {tram}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className={css('body')}>
                    <div style={{overflowX: 'scroll'}}>
                        <LineChart width={10*listInfo.length} height={300} data={listInfo}>
                            <Line
                                type="monotone"
                                dataKey="sal"
                                stroke="red"
                                strokeWidth={2}
                                name='Độ mặn (g/l)'
                                dot={{ r: 1, strokeWidth: 2, fill: 'red' }}
                            />
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                            <Tooltip allowEscapeViewBox={{ AllowInDimension: { x: false } }}
                                labelFormatter={() => ''}
                                labelStyle={{ fontSize: 12 }}
                                itemStyle={{ fontSize: 12 }} />
                            <XAxis dataKey="dateTime" tick={<CustomizedAxisTick />}/>
                            <YAxis tick={{ fontSize: 12 }} domain={[0, 4]}/>
                        </LineChart>
                    </div>
                <table className={css('table')}>
                    <thead className={css('thead')}>
                        <tr>
                            <th className={css('stt')} style={{fontWeight: 'bold'}}></th>
                            <th className={css('thoi-gian')} style={{fontWeight: 'bold'}}>Thời gian</th>
                            <th className={css('do-man')} style={{fontWeight: 'bold'}}>Độ mặn (g/l)</th>
                        </tr>
                    </thead>
                    <tbody className={css('tbody')}>
                        {listInfo && listInfo.map((x,i) => {
                            return(<tr>
                                <td className={css('stt')}>{i+1}</td>
                                <td className={css('thoi-gian')}>{format(new Date(x.dateTime), 'HH:00:00 dd/MM/yyyy')}</td>
                                <td className={css('do-man')}>{x.sal}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className={css('footer')}>
                <p className={css('label')}>Từ ngày</p>
                <input className={css('input')} type="date" value={fromDate} onChange={handleFromDateChange}/>
                <p className={css('label')}>Đến ngày</p>
                <input className={css('input')} type="date" value={toDate} onChange={handleToDateChange}/>
            </div>
        </Modal.Footer>
    </Modal>);
};

export default MapPopup;