import styles from './RiverPopup.module.scss';
import className from 'classnames/bind';
import Modal from 'react-bootstrap/Modal';

const css = className.bind(styles);

const RiverPopup = ({show=true, onHide, river=0}) => {
    return(<Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        {river == 1 && (
            <>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className={css('header')}>
                        Chiều dài lớn nhất (L<sub>Max</sub>) trên sông {river==1&&('Cổ Chiên')} 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className={css('table')}>
                        <thead className={css('thead')}>
                            <tr>
                                <th className={css('stt')} style={{fontWeight: 'bold'}}></th>
                                <th className={css('thang')} style={{fontWeight: 'bold'}}>Tháng</th>
                                <th className={css('lmax05')} style={{fontWeight: 'bold'}}>L<sub>Max 0.5</sub></th>
                                <th className={css('lmax1')} style={{fontWeight: 'bold'}}>L<sub>Max 1</sub></th>
                                <th className={css('lmax2')} style={{fontWeight: 'bold'}}>L<sub>Max 2</sub></th>
                                <th className={css('lmax4')} style={{fontWeight: 'bold'}}>L<sub>Max 4</sub></th>
                            </tr>
                        </thead>
                        <tbody className={css('tbody')}>
                            <tr>
                                <td className={css('stt')}>1</td>
                                <td className={css('thang')}>T1-2024</td>
                                <td className={css('lmax05')}>63,4</td>
                                <td className={css('lmax1')}>58,4</td>
                                <td className={css('lmax2')}>50,6</td>
                                <td className={css('lmax4')}>40,8</td>
                            </tr>
                            <tr>
                                <td className={css('stt')}>2</td>
                                <td className={css('thang')}>T2-2024</td>
                                <td className={css('lmax05')}>68,5</td>
                                <td className={css('lmax1')}>64,3</td>
                                <td className={css('lmax2')}>59,7</td>
                                <td className={css('lmax4')}>48,7</td>
                            </tr>
                            <tr>
                                <td className={css('stt')}>3</td>
                                <td className={css('thang')}>T3-2024</td>
                                <td className={css('lmax05')}>73,9</td>
                                <td className={css('lmax1')}>65,9</td>
                                <td className={css('lmax2')}>55,8</td>
                                <td className={css('lmax4')}>47</td>
                            </tr>
                            <tr>
                                <td className={css('stt')}>4</td>
                                <td className={css('thang')}>T4-2024</td>
                                <td className={css('lmax05')}>68,5</td>
                                <td className={css('lmax1')}>55,8</td>
                                <td className={css('lmax2')}>49,5</td>
                                <td className={css('lmax4')}>40,3</td>
                            </tr>
                            <tr>
                                <td className={css('stt')}>5</td>
                                <td className={css('thang')}>T5-2024</td>
                                <td className={css('lmax05')}>66,6</td>
                                <td className={css('lmax1')}>61,4</td>
                                <td className={css('lmax2')}>53,4</td>
                                <td className={css('lmax4')}>41,1</td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
            </>
        )}
        {river == 2 && (
            <>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className={css('header')}>
                        Chiều dài lớn nhất (L<sub>Max</sub>) trên sông {river==2&&('sông Hậu')} 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className={css('table')}>
                        <thead className={css('thead')}>
                            <tr>
                                <th className={css('stt')} style={{fontWeight: 'bold'}}></th>
                                <th className={css('thang')} style={{fontWeight: 'bold'}}>Tháng</th>
                                <th className={css('lmax05')} style={{fontWeight: 'bold'}}>L<sub>Max 0.5</sub></th>
                                <th className={css('lmax1')} style={{fontWeight: 'bold'}}>L<sub>Max 1</sub></th>
                                <th className={css('lmax2')} style={{fontWeight: 'bold'}}>L<sub>Max 2</sub></th>
                                <th className={css('lmax4')} style={{fontWeight: 'bold'}}>L<sub>Max 4</sub></th>
                            </tr>
                        </thead>
                        <tbody className={css('tbody')}>
                            <tr>
                                <td className={css('stt')}>1</td>
                                <td className={css('thang')}>T1-2024</td>
                                <td className={css('lmax05')}>62,6</td>
                                <td className={css('lmax1')}>58,5</td>
                                <td className={css('lmax2')}>55,3</td>
                                <td className={css('lmax4')}>48,1</td>
                            </tr>
                            <tr>
                                <td className={css('stt')}>2</td>
                                <td className={css('thang')}>T2-2024</td>
                                <td className={css('lmax05')}>68</td>
                                <td className={css('lmax1')}>64</td>
                                <td className={css('lmax2')}>56,4</td>
                                <td className={css('lmax4')}>46</td>
                            </tr>
                            <tr>
                                <td className={css('stt')}>3</td>
                                <td className={css('thang')}>T3-2024</td>
                                <td className={css('lmax05')}>66,7</td>
                                <td className={css('lmax1')}>60,7</td>
                                <td className={css('lmax2')}>55</td>
                                <td className={css('lmax4')}>50,6</td>
                            </tr>
                            <tr>
                                <td className={css('stt')}>4</td>
                                <td className={css('thang')}>T4-2024</td>
                                <td className={css('lmax05')}>63,8</td>
                                <td className={css('lmax1')}>58,4</td>
                                <td className={css('lmax2')}>49,9</td>
                                <td className={css('lmax4')}>39,4</td>
                            </tr>
                            <tr>
                                <td className={css('stt')}>5</td>
                                <td className={css('thang')}>T5-2024</td>
                                <td className={css('lmax05')}>62,7</td>
                                <td className={css('lmax1')}>56,2</td>
                                <td className={css('lmax2')}>47,9</td>
                                <td className={css('lmax4')}>34,6</td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
            </>
        )}
        
    </Modal>);
};

export default RiverPopup;