import Header from './Header';
import className from 'classnames/bind';
import style from './DefaultLayout.module.scss';

const css = className.bind(style);

const DefaultLayout = ({ children }) => {
    return (
        <div className={css('layout')}>
            <Header/>
            <div>
                <div>{children}</div>    
            </div>
        </div>
    );
};

export default DefaultLayout;