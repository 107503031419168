import { useState, useEffect, useRef } from 'react';
import styles from './Header.module.scss';
import className from 'classnames/bind';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import LoginPopup from '~/Components/Controls/LoginPopup';
import logo_tram from '~/Assets/Images/logo_vl.png';

const css = className.bind(styles);

const Header = () => {
    const [isLogin, setIsLogin] = useState(false);
    const [isShowLoginPopup, setShowLoginPopup] = useState(false);
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const loginRef = useRef(null);

    const toggleDropdown = () => {
        setIsShowDropdown(!isShowDropdown);
    };

    const handleLoginRef = (event) => {
        if (loginRef.current && !loginRef.current.contains(event.target)) {
            setIsShowDropdown(false);
        }
    };

    const handleClickLogin = () => {
        setShowLoginPopup(true);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleLoginRef);
        return () => {
            document.removeEventListener('mousedown', handleLoginRef);
        };
    },[]);

    return (<>
        <div className={css('navbar')}>
            <div className={css('container')}>   
                <div className={css('logo')}>
                    {window.innerWidth>768 && (
                        <Container fluid>
                            <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Col xs={1} md={1}>
                                    <img src={logo_tram} width={60} height={60}/>
                                </Col>
                                <Col xs={14} md={9}><p className={css('title')}>Dự báo tác động của xâm nhập mặn và biển đổi khí hậu đối với 
                                phát triển kinh tế - xã hội,<br/> môi trường của tỉnh Vĩnh Long và giải pháp ứng phó</p></Col>
                                <Col xs={4} md={2}>
                                    {isLogin?<div ref={loginRef}>
                                        <div className={css(isShowDropdown?'show-dropdown':'btn-authen')} onClick={toggleDropdown}><div className={css('underline')}>Hi, Nguyễn Đăng Khoa</div></div>
                                        <Dropdown.Menu show style={{width: '18rem', fontSize:'1.3rem', display:isShowDropdown?'':'none'}}>
                                            <Dropdown.Item eventKey="1">Hồ sơ</Dropdown.Item>
                                            <Dropdown.Item eventKey="2">Hướng dẫn</Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item eventKey="4"><Link to="/">Đăng xuất</Link></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </div>:
                                    <p className={css('btn-authen','underline')} onClick={handleClickLogin}>Đăng nhập</p>}
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {window.innerWidth<=768 && (
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <img src={logo_tram} className={css('img')}/>
                            <p style={{fontSize: '1vh', paddingLeft: '1rem'}}>Dự báo tác động của xâm nhập mặn và biển đổi khí hậu đối với 
                                phát triển kinh tế - xã hội, môi trường của tỉnh Vĩnh Long và giải pháp ứng phó</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
        <LoginPopup
            show={isShowLoginPopup}
            onHide={() => setShowLoginPopup(false)}
        />
    </>);
};

export default Header;