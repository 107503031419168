import { appConfig } from '~/Config/AppConfig';
import axios from 'axios';

const path = appConfig.API+'/api/v1/data/';

class Data {
    GetDateByDateTime(TramId, FromDate, ToDate) {
        return axios.get(path+`get-data-by-date-time/${TramId}/${FromDate}/${ToDate}`)
        .then(response => response.data)
        .catch(error => error.response.data);
    };
}

export default Data;