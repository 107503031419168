import React from 'react';
import styles from './NoteLayer.module.scss';
import className from 'classnames/bind';
import river from '~/Assets/Images/river.png';
import tram from '~/Assets/Images/tram.png';

const css = className.bind(styles);

const NoteLayer = () => {
    return (<div 
        style={{
            position: 'absolute',
            bottom: '0.5rem',
            right: '1rem',
            backgroundColor: 'white',
            padding: '1rem',
            borderRadius: '0.5rem',
            boxShadow: '0 0.2rem 0.5rem rgba(0,0,0,0.1)',
            cursor: 'pointer',
            zIndex: '401',
            border: '2px solid rgba(0, 0, 0, 0.2)',
            width: '20rem'
        }}
    >
        <p className={css('title')}>CHÚ THÍCH</p>
        <div className={css('item')}>
            <div><img src={river} height={'16rem'}/></div>
            <div><p style={{width: '100%', marginLeft: '1rem'}}>Dòng sông</p></div>
        </div>
        <div className={css('item')}>
            <div><div style={{backgroundColor: 'rgb(51, 136, 255)', height: '0.3rem', width: '6rem', justifySelf: 'center', alignItems: 'center', display: 'flex', marginTop: '0.6rem'}}></div></div>
            <div><p style={{width: '100%', marginLeft: '1rem'}}>Ranh giới huyện</p></div>
        </div>
        <div className={css('item')}>
            <div style={{width: '6rem', textAlign: 'center'}}><img src={tram} height={'16rem'}/></div>
            <div><p style={{width: '100%', marginLeft: '1rem'}}>Điểm dự báo mặn</p></div>
        </div>
    </div>);
};

export default NoteLayer;