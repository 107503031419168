import React from 'react';
import styles from './DensityPopup.module.scss';
import className from 'classnames/bind';

const css = className.bind(styles);

const DensityPopup = () => {
    return (<div 
        style={{
            position: 'absolute',
            bottom: '0.5rem',
            left: '1rem',
            backgroundColor: 'white',
            padding: '1rem',
            borderRadius: '0.5rem',
            boxShadow: '0 0.2rem 0.5rem rgba(0,0,0,0.1)',
            cursor: 'pointer',
            zIndex: '401',
            border: '2px solid rgba(0, 0, 0, 0.2)',
            width: '20rem'
    }}>
        <p className={css('title')}>ĐỘ MẶN (g/l)</p>
        <div className={css('item')}>
            <div><div style={{backgroundColor: '#ff0000', height: '1rem', width: '6rem'}}></div></div>
            <div><p style={{width: '100%', marginLeft: '1rem'}}> {'>'}= 4</p></div>
        </div>
        <div className={css('item')}>
            <div><div style={{backgroundColor: '#ffa500', height: '1rem', width: '6rem'}}></div></div>
            <div><p style={{width: '100%', marginLeft: '1rem'}}>2 - 4</p></div>
        </div>
        <div className={css('item')}>
            <div><div style={{backgroundColor: '#ffff00', height: '1rem', width: '6rem'}}></div></div>
            <div><p style={{width: '100%', marginLeft: '1rem'}}>1 - 2</p></div>
        </div>
        <div className={css('item')}>
            <div><div style={{backgroundColor: '#00ff00', height: '1rem', width: '6rem'}}></div></div>
            <div><p style={{width: '100%', marginLeft: '1rem'}}>0.5 - 1</p></div>
        </div>
        <div className={css('item')}>
            <div><div style={{backgroundColor: '#00ffff', height: '1rem', width: '6rem'}}></div></div>
            <div><p style={{width: '100%', marginLeft: '1rem'}}>Dưới 0.5</p></div>
        </div>
    </div>)
}

export default DensityPopup;